import React, { useState } from 'react';
import './single.scss';
import upload from '../../config/upload';
import newRequest from '../../config/newRequest';



const EditMember = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    //EDIT / UPDATE USER
    const [file, setFile] = useState("");

    const [member, setMember] = useState({
        username: currentUser.username,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        email: currentUser.email,
        nin: currentUser.nin,
        address: currentUser.address,
        phone: currentUser.phone,
        jobTitle: currentUser.jobTitle,
        employer: currentUser.employer,
        business: currentUser.business,
        accessToken: currentUser.accessToken,
    });


    const handleChange = (e) => {
        setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    const handleClick = async (e) => {
        e.preventDefault();
        const url = await upload(file);
    
        try {

            const updatedUser = {
                profile: url,
            };

            const res = await newRequest.put("/api/user/update/" + currentUser._id, updatedUser);
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            window.location.reload();
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedUser = {
                ...member
            };

            const res = await newRequest.put("/api/user/update/" + currentUser._id, updatedUser);
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            window.location.reload();
        } catch (err) {
            return(err);
        }

    }



    return (
        <div className='editMember'>

            <div className="wrapper">

                <div className="singleLeft">
                    <h2 className="title">User Details:</h2>
                    <div className="item">
                        <img
                            src={currentUser.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                            alt=""
                            className="itemImg"
                        />

                        <div className="itemDetails">
                            <h3 className="detailsTitle">{currentUser.firstname + " " + currentUser.lastname}</h3>
                            <div className="detailsItem">
                                <span className="itemKey">NIN:</span>
                                <span className="itemValue">{currentUser.nin}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Email:</span>
                                <span className="itemValue">{currentUser.email}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Address:</span>
                                <span className="itemValue">{currentUser.address}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Phone No:</span>
                                <span className="itemValue">{currentUser.phone}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Job:</span>
                                <div className="text">
                                    <span className="itemValue">{currentUser.jobTitle + " "}  at </span>
                                    <span className="itemValue">{currentUser.employer}</span>
                                </div>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Personal Business:</span>
                                <span className="itemValue">{currentUser.business}</span>
                            </div>
                        </div>
                    </div>

                    <form>

                        <div className="itemForm">

                            <div className="imgInputWrapper">

                                <div className="leftImg">
                                    <img
                                        src={
                                            file
                                                ? URL.createObjectURL(file)
                                                : "https://media.istockphoto.com/vectors/default-image-icon-vector-missing-picture-page-for-website-design-or-vector-id1357365823?k=20&m=1357365823&s=612x612&w=0&h=ZH0MQpeUoSHM3G2AWzc8KkGYRg4uP_kuu0Za8GFxdFc="
                                        }
                                        alt=""
                                    />

                                </div>

                            </div>

                            <div className="imgInput">

                                <label htmlFor="file" >
                                    Click to Upload User Image:
                                </label>

                                <input
                                    type="file" id="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => setFile(e.target.files[0])}
                                />

                            </div>

                        </div>

                        <button onClick={handleClick} className="itemButton" >Update User Image</button>

                    </form>
                </div>


                <div className="singleRight">
                    <h2 className="title">Edit User Info:</h2>

                    <form onSubmit={handleSubmit}>
                        <div className='editForm'>

                            <input
                                type="text"
                                name="username"
                                value={member.username}
                                onChange={handleChange}
                            />


                            <input
                                type="text"
                                name="firstname"
                                value={member.firstname}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="lastname"
                                value={member.lastname}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="nin"
                                value={member.nin}
                                onChange={handleChange}
                            />

                            <input
                                type="text"
                                name="phone"
                                value={member.phone}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="email"
                                value={member.email}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="address"
                                value={member.address}
                                placeholder='Where do you live?'
                                onChange={handleChange}
                            />
                             <input
                                type="text"
                                name="jobTitle"
                                value={member.jobTitle}
                                onChange={handleChange}
                            />

                            <input
                               type="text"
                               name="employer"
                               value={member.employer}
                               placeholder='Employers Name'
                               onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="business"
                                value={member.business}
                                placeholder='Your Business Name or Side Hustle'
                                onChange={handleChange}
                            />

                            <button type='submit' className="formButton" onClick={scrollToTop}>Update</button>

                        </div>

                    </form>

                </div>

            </div>

        </div>
    )
}

export default EditMember