import React, { useState } from "react";
import "./register.scss";
import upload from "../config/upload";
import { IoMdPhotos } from 'react-icons/io';
import { useNavigate, Link } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { useForm } from "react-hook-form";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";



function Register() {
    const [file, setFile] = useState(null);
    const [user, setUser] = useState({
        username: "",
        firstname: "",
        lastname: "",
        jobTitle: "",
        employer: "",
        address: "",
        phone: "",
        nin: "",
        email: "",
        password: "",
        isGroupLeader: false,
    });

    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const { handleSubmit } = useForm();

    const navigate = useNavigate();

    //show and hide password
    const [isReveal, setIsReveal] = useState(false);
    const togglePassword = () => {
        setIsReveal(!isReveal);
    }

    const handleChange = (e) => {
        setUser((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    function refreshPage() {
        window.location.reload();
    }

    const handleRegister = async (e) => {
        // e.preventDefault();
        setLoading(true);
        const url = await upload(file);
        try {
            await axios.post("https://regonow-backend-5bd998beedc2.herokuapp.com/api/auth/register", {
                ...user,
                profile: url,
            });
            setLoading(false);
            navigate("/login")
        } catch (err) {
            setError(err.response.data);
        }
    };


    return (
        <div className="register">

            <div className="top">
                <img className="logoImage" src={process.env.PUBLIC_URL + '/dotlogo.png'} alt="logo" />
                <span className="logoText">regonow</span>
            </div>

            <div className="form">
                <h3>Create a New Account</h3>

                <form onSubmit={handleSubmit(handleRegister)}>
                    <div className="left">
                        <input
                            name="username"
                            type="text"
                            required
                            placeholder="User Name"
                            onChange={handleChange}
                            className="leftInput"
                        />

                        <input
                            name="firstname"
                            type="text"
                            required
                            placeholder="First Name"
                            onChange={handleChange}
                            className="leftInput"
                        />
                        <input
                            name="lastname"
                            type="text"
                            required
                            placeholder="Last Name"
                            onChange={handleChange}
                            className="leftInput"
                        />
                        <input
                            name="email"
                            type="email"
                            required
                            placeholder="Email"
                            onChange={handleChange}
                            className="leftInput"
                        />

                        <div className="passwordWrapper">
                            <div className="password">
                                <input
                                    placeholder="******"
                                    type={isReveal ? "text" : "password"}
                                    minLength="6"
                                    required
                                    name="password"
                                    onChange={handleChange}
                                />
                                <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                            </div>
                            <p >*Password MUST be atleast 6 characters long*</p>
                        </div>


                        <LoadingButton
                            type="submit"
                            loading={loading}
                            variant="contained"
                            className="disButton"
                        >
                            Register
                        </LoadingButton>
                    </div>

                    <div className="right">

                        <input
                            name="phone"
                            type="text"
                            required
                            placeholder="Phone:  +256..."
                            onChange={handleChange}
                            className="rightInput"
                        />
                        <input
                            name="nin"
                            type="text"
                            required
                            placeholder="Enter ID Number(NIN)"
                            onChange={handleChange}
                            className="rightInput"
                        />
                        <input
                            name="address"
                            type="text"
                            placeholder="Address"
                            onChange={handleChange}
                            className="rightInput"
                        />
                        <input
                            name="jobTitle"
                            type="text"
                            placeholder="Job Title"
                            onChange={handleChange}
                            className="rightInput"
                        />
                        <input
                            name="employer"
                            type="text"
                            placeholder="Employer"
                            onChange={handleChange}
                            className="rightInput"
                        />

                        <div className="profilePic">
                            <div className="imgInput">
                                <label htmlFor="file">
                                    Upload Profile Image: <MdUploadFile className='uploadIcon' />
                                </label>
                                <input
                                    type="file" id="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>

                            <img
                                className="profileUpload"
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"
                                }
                                alt=""
                            />
                        </div>

                        <LoadingButton
                            type="submit"
                            loading={loading}
                            variant="contained"
                            color="success"
                            className="resButton"
                        >
                            <span>Register</span>
                        </LoadingButton>

                    </div>
                </form>

            </div>

            <div className="error">
                <span style={{ color: '#e22d64', fontSize: 14 }}>{err && err}</span>
                {err && <button type="button" onClick={refreshPage}> <span>TRY AGAIN</span> </button>}
            </div>

            <div className="bottom">

                <div className="bottomItem">
                    <span>Already have an Account?</span>
                    <Link className='link' to="/login" >
                        <p>Login</p>
                    </Link>
                </div>

                <Link to="/forgot-password" className='link'>
                    <span className='password'>Forgot Password?</span>
                </Link>

            </div>
        </div>
    );
}

export default Register;
