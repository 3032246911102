import React, { useState } from 'react';
import "./login.scss";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import NavBar2 from '../Components/navbar/NavBar2';



function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    //show and hide password
    const [isReveal, setIsReveal] = useState(false);
    const togglePassword = () => {
        setIsReveal(!isReveal);
    }


    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const res = await axios.post("https://regonow-backend-5bd998beedc2.herokuapp.com/api/auth/login",
                // const res = await axios.post("http://localhost:4400/api/auth/login",
                { email, password },
                { withCredentials: true }
            );
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            setLoading(false);
            navigate("/")
        } catch (err) { }
    };

    return (
        <>
        {/* <NavBar2 /> */}

        <div className='login'>

            <div className="loginWrapper">

                <div className="top">
                    <img className="logoImage" src={process.env.PUBLIC_URL + '/dotlogo.png'} alt="logo" />
                    <span className="logoText">regonow</span>
                </div>

                <div className='form'>

                    <h3>Login to your Account</h3>

                    <form onSubmit={handleSubmit}>
                        <input
                            placeholder='Email'
                            type="email"
                            required
                            name="email"
                            onChange={e => setEmail(e.target.value)}

                        />

                        <div className="password">
                            <input
                                placeholder='Password'
                                type={isReveal ? "text" : "password"}
                                minLength="6"
                                required
                                name="password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                        </div>

                        <button type="submit" disabled={loading} className={loading ? 'loadingButton' : 'activeButton'}>
                            {loading ? <CircularProgress color="primary" size="18px"/> : <p>Login</p>}
                        </button>

                        <span style={{ color: 'red' }}>{error && error}</span>

                    </form>

                </div>

                <div className="bottom">

                    <div className="bottomItem">
                        <span>Do not have an Account?</span>
                        <Link className='link' to="/register" >
                            <p>Register</p>
                        </Link>
                    </div>

                    <Link to="/forgot-password" className='link'>
                        <span className='password'>Forgot Password?</span>
                    </Link>
                </div>

            </div>

        </div>
        </>
    )
}

export default Login


