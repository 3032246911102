import React, { useState } from 'react';
import './withdraw.scss';
import newRequest from '../../config/newRequest';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { LoadingButton } from '@mui/lab';

const Withdraw = ({ group, member, wallets }) => {

    // const [err, setErr] = useState(null);
    const navigate = useNavigate();

    const fullname = member.firstname + " " + member.lastname;

    // const [network, setNetwork] = useState('');
    const [loading, setLoading] = useState(false);



    const [payout, setPayout] = useState({
        phone_number: member.phone,
        fullname: fullname,
        email: member.email,
        amount: "",
        groupId: group._id,
        userId: member._id,
    });

    // console.log(payout.groupname)
    console.log(payout)
    console.log(payout.email, payout.phone_number)

    const handleChange = (e) => {
        setPayout((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        try {
            const newWithdraw = {
                ...payout,
            }
            await newRequest.post("https://buaca-37e1a7634329.herokuapp.com/api/payout/withdraw/initiate", newWithdraw);
            // await axios.post("http://localhost:4400/api/payout/withdraw/initiate", newWithdraw);
            navigate("/confirm-payout")
        } catch (err) { }
    };


    return (

        <div className="withdrawWrapper">
            <h3>Withdraw from Group Account</h3>
            <p>Group: {group.name}</p>

            <form onSubmit={handleSubmit}>

                <div className="payout">
                    <div className="payoutItem">
                        <label>Fullname</label>
                        <input
                            type="text"
                            name="fullname"
                            value={fullname}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="payoutItem">
                        <label>Phone Number</label>
                        <input
                            type="text"
                            name="phone_number"
                            value={member.phone}
                            onChange={handleChange}
                        />
                    </div>

                    {/* <div className="payoutItem">
                        <label>Amount</label>
                        <input
                            type="text"
                            name="amount"
                            placeholder='e.g 1000'
                            value={payout.amount}
                            onChange={handleChange}
                        />
                    </div> */}

                    <div className="payoutItem">
                        <label>Amount</label>
                        {wallets.map((wallet) =>
                            member._id === wallet.userId && (

                                <>
                                    <p>*Available balance is be 500 to {wallet.balance?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} ugx</p>
                                    <input
                                        type="number"
                                        name="amount"
                                        min={500}
                                        max={wallet.balance}
                                        placeholder='e.g 1000'
                                        value={payout.amount}
                                        onChange={handleChange}
                                    />

                                </>

                            ))}
                    </div>

                    <button type="submit" loading={loading} className="payoutBtn">Withdraw</button>

                </div>

            </form>


        </div>
    )
}

export default Withdraw