import React, { useEffect, useState } from 'react';
import './editGroup.scss';
import { useLocation } from 'react-router-dom';
import upload from '../../config/upload';
import newRequest from '../../config/newRequest';
import { useToast } from '@chakra-ui/react';
import UserItem from '../users/UserItem';


const EditGroup = () => {

    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [group, setGroup] = useState({});
    const [groupAdmin, setGroupAdmin] = useState({});
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();


    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [file, setFile] = useState("");
    const [groupEdit, setGroupEdit] = useState("");


    //GET SINGLE GROUP
    useEffect(() => {
        const getGroup = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const singleGroup = await newRequest.get("/api/group/single/" + path, config);
                setGroup(singleGroup.data);
            } catch (err) { }
        };
        getGroup();
    }, [path, currentUser.accessToken, group]);

    //GROUP ADMIN
    useEffect(() => {
        const fetchMember = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            const res = await newRequest.get(`/api/user/users?userId=${group.groupAdmin}`, config);
            setGroupAdmin(res.data);
        };
        fetchMember();
    }, [group.groupAdmin, currentUser.accessToken]);

    //UPDATE GROUP NAME
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedGroup = {
                name: groupEdit
            };

            await newRequest.put("/api/group/update/" + path, updatedGroup);
            window.location.reload();
        } catch (err) {
            return (err);
        }

    }

    //UPDATE GROUP IMAGE
    const handleClick = async (e) => {
        e.preventDefault();
        const url = await upload(file);
    
        try {

            const updatedGroup = {
                image: url,
            };

            await newRequest.put("/api/group/update/" + path, updatedGroup);
            window.location.reload();
        } catch (err) {
            console.log(err.response.data);
        }
    };


    //SEARCH THE USER TO ADD
    const handleSearch = async (query) => {
        setSearch(query);
        if (!query) {
            return;
        }

        try {
            setLoading(true);
            const { data } = await newRequest.get(`/api/user?search=${search}`);
            console.log(data);
            setLoading(false);
            setSearchResult(data);
        } catch (error) {
            toast({
                title: "Error Occured!",
                description: "Failed to Load the Search Results",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    };


    //ADD NEW MEMBER TO GROUP
    const handleAddMember = async (user1) => {
        if (group.users.find((u) => u._id === user1._id)) {
            toast({
                title: "User Already in group!",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
        }

        try {
            setLoading(true);

            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };

            const addUser = {
                groupId: group._id,
                userId: user1._id,
            };

            await newRequest.put("/api/group/add/" + path, addUser,config );
            setLoading(true);
            alert("New Member Added!!");
            window.location.reload();
        } catch (error) {
            // setError(error);
        }
    };


    
    return (
        <div className='editGroup'>

            <div className="wrapper">

                <div className="editLeft">
                    <h2 className="title">Group Details:</h2>
                    <div className="item">
                        <img
                            src={group.image || "https://res.cloudinary.com/tunjooadmin/image/upload/v1683892793/Copy_of_Copy_of_m_jon6ug.png"}
                            alt=""
                            className="itemImg"
                        />

                        <div className="itemDetails">
                            <h3>{group.name}</h3>
                            <div className="detailsItem">
                                <span className="itemKey">Group Admin:</span>
                                <span className="itemValue">{groupAdmin.firstname + " " + groupAdmin.lastname}</span>
                            </div>

                            <div className="detailsItem2">
                                <span className="itemKey2">Members:</span>
                                <span className="itemValue2">{group.users ? group.users?.length : "0"}</span>
                            </div>
                        </div>
                    </div>

                    <form>

                        <div className="itemForm">

                            <div className="imgInputWrapper">

                                <div className="leftImg">
                                    <img
                                        src={
                                            file
                                                ? URL.createObjectURL(file)
                                                : "https://media.istockphoto.com/vectors/default-image-icon-vector-missing-picture-page-for-website-design-or-vector-id1357365823?k=20&m=1357365823&s=612x612&w=0&h=ZH0MQpeUoSHM3G2AWzc8KkGYRg4uP_kuu0Za8GFxdFc="
                                        }
                                        alt=""
                                    />

                                </div>

                            </div>

                            <div className="imgInput">

                                <label htmlFor="file" >
                                    Click to Upload Image: 
                                </label>

                                <input
                                    type="file" id="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => setFile(e.target.files[0])}
                                />

                            </div>

                        </div>

                        <button onClick={handleClick} className="itemButton" >Update Group Image</button>

                    </form>
                </div>


                <div className="editRight">

                    <form onSubmit={handleSubmit}>
                        <div className='editForm'>
                            <h2 className="title">Edit Group Name:</h2>
                            <input
                                type="text"
                                name="username"
                                value={groupEdit}
                                onChange={(e) => setGroupEdit(e.target.value)}
                            />

                            <button type='submit' className="formButton" onClick={scrollToTop}>Update Group Name</button>

                        </div>

                    </form>

                    <form>
                        <div className='editForm'>
                            <h2 className="title">Add New Member:</h2>
                            <input
                                type="text"
                                placeholder='e.g: Nancy,  John'
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            <div className="select">
                                {/* render searched users */}
                                {loading ? (
                                    <div style={{ color: 'gray' }}>Loading...</div>
                                ) : (
                                    searchResult?.map((user) => (
                                        <UserItem
                                            key={user._id}
                                            user={user}
                                            handleFunction={() => handleAddMember(user)}
                                        />
                                    ))
                                )}
                            </div>

                        </div>

                    </form>

                </div>

            </div>

        </div>
    )
}

export default EditGroup
