import React from 'react';
import './app.scss';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    Navigate,
} from "react-router-dom";

import Login from './forms/Login';
import Register from './forms/Register';
import Home from './Pages/home/Home';
import Group from './Pages/groups/Group';
import Wallet from './Pages/account/Account';
import NavBar from './Components/navbar/NavBar';
import SuccessModal from './forms/SuccessModal';
import SingleMember from './Pages/single/SingleMember';
import EditMember from './Pages/single/EditMember';
import FundWallet from './forms/modals/FundWallet';
import DepositGroup from './forms/modals/DepositGroup';
import Contact from './Pages/contact/Contact';
import EditGroup from './Components/newGroup/EditGroup';
import ForgotPassword from './forms/ForgotPassword';
import ResetPassword from './forms/ResetPassword';
import Response from './Pages/payment-response/Response';
import NewGroup from './Components/newGroup/NewGroup';
import Withdraw from './forms/modals/Withdraw';
import Confirm from './Pages/payment-response/Confirm';


function App() {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    //MAIN LAYOUT for homepage and profilepage
    const Layout = () => {
        return (
            <div>
                <NavBar />
                <div >
                    <Outlet />
                </div>

            </div>
        )
    };


    //Allow only logged in user to view homepage
    const ProtectedRoute = ({ children }) => {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));

        if (!currentUser) {
            return <Navigate to="/login" />;
        } else {
            return children;
        }
    }

    //ROUTER
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <ProtectedRoute currentUser={currentUser}>
                    <Layout />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path:"/group/:groupid",
                    element: <Group />,
                },
                {
                    path: "/:groupId/:username",
                    element: <SingleMember />,
                },
                {
                    path: "/member/edit/:memberid",
                    element: <EditMember />,
                },
                {
                    path: "/account/:username",
                    element: <Wallet />,
                },
                {
                    path: "/contact-us",
                    element: <Contact />,
                },
                {
                    path: "/new-group",
                    element: <NewGroup />,
                },
                {
                    path:"/edit/:groupId/:groupName",
                    element: <EditGroup />,
                },
                {
                    path:"/fund-your-wallet",
                    element: <FundWallet />,
                },
                {
                    path:"/fund-your-group/:groupId",
                    element: <DepositGroup />,
                },
                {
                    path:"/withdraw-funds/:groupId",
                    element: <Withdraw />,
                },
                {
                    path:"/response",
                    element: <Response />,
                },
                {
                    path:"/confirm-payout",
                    element: <Confirm />,
                },
            ]
        },
        {
            path: "/deposit-cart",
            element: <FundWallet />
        },
        {
            path: "/fund-group",
            element: <DepositGroup/>
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/register",
            element: <Register />
        },
        {
            path: "/successModal",
            element: <SuccessModal />
        },  
        {
            path: "/forgot-password",
            element: <ForgotPassword />
        },  
        {
            path: "/reset-password/:resetToken",
            element: <ResetPassword />
        },  



    ])


    return (
        <div className='container' id="container" >
            <RouterProvider router={router} />
            
        </div>
    )
}

export default App
