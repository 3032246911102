import React, { useState } from 'react';
import "./login.scss";
import { Link } from 'react-router-dom';
import newRequest from '../config/newRequest';
import ResetModal from './ResetModal';
import axios from 'axios';



function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post("https://regonow-backend-5bd998beedc2.herokuapp.com/api/auth/forgot-password",
                { email: email },
            );
            if (res.data) {
                setShowModal({
                    title: "Success!",
                    message: res.data.message,
                });
                return;
            }
        } catch (err) {
            setError(err.response.data);
        }
    };

    const modalHandler = () => {
        setShowModal(null);
    };



    return (
        <div className='login'>

            <div className="loginWrapper">

                <div className="top">
                    <img className="logoImage" src={process.env.PUBLIC_URL + '/dotlogo.png'} alt="logo" />
                    <span className="logoText">regonow</span>
                </div>


                {showModal && (
                    <ResetModal
                        title={showModal.title}
                        message={showModal.message}
                        onShowModal={modalHandler}
                    />
                )}

                <div className='form'>

                    <h4>Enter your email to reset password</h4>

                    <form onSubmit={handleSubmit}>
                        <input
                            placeholder='Email'
                            type="email"
                            required
                            name="email"
                            onChange={e => setEmail(e.target.value)}

                        />

                        <button type="submit" className='activeButton'>
                            <p>Submit</p>
                        </button>

                        <span
                            style={{
                                color: 'red',
                                fontSize: '13px',
                                fontWeight: 300,
                                fontFamily: "'Roboto', sans-serif"
                            }}>
                            {error && error}
                        </span>


                    </form>

                </div>

                <div className="bottom">

                    <div className="bottomItem">
                        <span>Do not have an Account?</span>
                        <Link className='link' to="/register" >
                            <p>Register</p>
                        </Link>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ForgotPassword