import React, { useRef, useState } from 'react';
import "./login.scss";
import PasswordSuccess from './PasswordSuccess';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import newRequest from '../config/newRequest';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function ResetPassword() {

    const password = useRef();
    const confirmPassword = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [showModal, setShowModal] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [isReveal, setIsReveal] = useState(false);

    const togglePassword = () => {
        setIsReveal(!isReveal);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (confirmPassword.current.value !== password.current.value) {
            confirmPassword.current.setCustomValidity("Passwords don't match!");
        } else {
            try {
                setLoading(true);
                const user = {
                    password: password.current.value,
                };

                const res = await axios.put("https://regonow-backend-5bd998beedc2.herokuapp.com/api/auth/reset-password/" + path, user);

                if (res.data) {
                    setShowModal({
                        title: "Success!",
                        message: res.data.message,
                    });
                    return;
                }
                setLoading(false);
                // navigate("/login");
            } catch (err) {
                setError(err.response.data);
            }
        }
    };

    const modalHandler = () => {
        setShowModal(null);
    };


    return (
        <div className='login'>

            <div className="loginWrapper">

                <div className="top">
                    <img className="logoImage" src={process.env.PUBLIC_URL + '/dotlogo.png'} alt="logo" />
                    <span className="logoText">regonow</span>
                </div>

                {showModal && (
                    <PasswordSuccess
                        title={showModal.title}
                        message={showModal.message}
                        onShowModal={modalHandler}
                    />
                )}


                <div className='form'>

                    <h3>Reset Password</h3>

                    <form onSubmit={handleSubmit}>

                        <div className="password">
                            <input
                                placeholder='New Password'
                                type={isReveal ? "text" : "password"}
                                minLength="6"
                                required
                                name="password"
                                ref={password}
                            />
                            <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                        </div>

                        <div className="password">
                            <input
                                placeholder='Confirm New Password'
                                type={isReveal ? "text" : "password"}
                                required
                                name="email"
                                ref={confirmPassword}

                            />

                        </div>


                        <button type="submit">
                           { loading ? <CircularProgress color="inherit" /> : <p>Reset Password</p>}
                        </button>

                        <span style={{ color: 'red', marginTop: 20 }}>{error && error}</span>
                        {error &&
                            <Link to="/forgot-password" className='link'>
                                <span style={{ color: '#ba68c8', fontSize: 14, textDecoration: 'underline' }}>Try Again: Forgot Password?</span>
                            </Link>
                        }


                    </form>

                </div>

            </div>

        </div>
    )
}

export default ResetPassword