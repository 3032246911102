import React, { useState, useEffect, useMemo } from 'react';
import './home.scss';
import '../../Components/leftbar/leftbar.scss';
import './modal.scss';
import '../../Components/leftbar/leftbar.scss';
import { HiPlus } from 'react-icons/hi';
import { Link, NavLink } from "react-router-dom";
import { IoClose } from 'react-icons/io5';
import newRequest from '../../config/newRequest';
import UserChart from '../../Components/stats/UserChart';
import LeftBar from '../../Components/leftbar/LeftBar';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { InstagramEmbed } from 'react-social-media-embed';
import axios from 'axios';
import { BsFillPersonFill } from "react-icons/bs";
import { MdGroups } from "react-icons/md";
import { LinkedInEmbed } from 'react-social-media-embed';


const Home = () => {

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    //total users
    const [userCount, setUserCount] = useState("");
    useEffect(() => {
        const getCount = async () => {
            try {
                const res = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/user/usercount");
                setUserCount(res.data);
            } catch { }
        };
        getCount();
    });


    //total groups
    const [groupCount, setGroupCount] = useState("");
    useEffect(() => {
        const getCount = async () => {
            try {
                const res = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/group/groupcount");
                setGroupCount(res.data);
            } catch { }
        };
        getCount();
    });

    //User Stats
    const [userStats, setUserStats] = useState([]);
    const MONTHS = useMemo(
        () => [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'June',
            'July',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec'
        ],
        []
    );

    useEffect(() => {
        const getStats = async () => {
            try {
                const res = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/user/stats");
                const statsList = res.data.sort(function (a, b) {
                    return a._id - b._id;
                });
                statsList.map((item) =>
                    setUserStats((prev) => [
                        ...prev,
                        { name: MONTHS[item._id - 1], "New User": item.total },
                    ])
                );
            } catch (err) {
                console.log(err);
            }
        };
        getStats();
    }, [MONTHS]);

    const [currentMonth, setCurrentMonth] = useState();
    useEffect(() => {
        const getMonthlyCount = async () => {
            try {
                const res = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/user/currentUser");
                setCurrentMonth(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getMonthlyCount();
    })

    const [prevMonth, setPrevMonth] = useState();
    useEffect(() => {
        const getMonthlyCount = async () => {
            try {
                const res = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/user/prevUser");
                setPrevMonth(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getMonthlyCount();
    })

    const monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const d = new Date();
    const thisMonth = monthArr[d.getMonth()];
    const lastMonth = monthArr[d.getMonth() - 1];


    //FOR RESPONSIVE SIDE BAR
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [groups, setGroups] = useState([]);

    // //GET GROUPLIST
    useEffect(() => {
        const getGroups = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const groupList = await newRequest.get("/api/group/groups/" + currentUser._id, config);
                setGroups(groupList.data);
            } catch (err) {
                // console.log(err);
            }
        };
        getGroups();
    }, [currentUser]);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }




    return (

        <div className="homeContainer">

            <LeftBar />

            <div className='homePage'>

                <div className="homeWrapper">

                    <div className="homeTop">

                        <div className="left">
                            <IoIosArrowDropleftCircle className='toggle' onClick={showSidebar} />
                            <span className='pageTitle'>Home</span>
                        </div>

                        <div className="right" >
                            <Link to="/new-group" className='link'>
                                <div className="createBtn">
                                    <p>Create New Group</p>
                                    <HiPlus className='icon' />
                                </div>
                            </Link>
                        </div>

                    </div>

                    <div className="homeBottom">

                        <div className="totalGroups">
                            {/* <span className='title'>Users on Regonow</span> */}
                            <div className="wrapper">

                                <div className='totalsWrapper'>

                                    <div className="totals">
                                        <div className="totalUsers">
                                            <div className='totalsItem'>
                                                <span>Total Users</span>
                                                <BsFillPersonFill className='itemIcon' />
                                            </div>

                                            <h2>{userCount}</h2>
                                            <p>Registered last month</p>
                                        </div>

                                        <div className="totalUsers">
                                            <div className='totalsItem'>
                                                <span>Total Groups</span>
                                                <MdGroups className='itemIcon' />
                                            </div>

                                            <h2>{groupCount < 10 ? '0' + groupCount : groupCount}</h2>
                                            <p>Created last month</p>

                                        </div>

                                    </div>

                                    <div className="totals">
                                        <div className="totalUsers">
                                            <div className='totalsItem'>
                                                <span>Users</span>
                                                <BsFillPersonFill className='itemIcon' />
                                            </div>

                                            <h2>{prevMonth < 10 ? '0' + prevMonth : prevMonth}</h2>
                                            <p>Registered in {lastMonth}</p>

                                        </div>

                                        <div className="totalUsers">
                                            <div className='totalsItem'>
                                                <span>Users</span>
                                                <BsFillPersonFill className='itemIcon' />
                                            </div>

                                            <h2>{currentMonth < 10 ? '0' + currentMonth : currentMonth}</h2>
                                            <p>Registered in {thisMonth}</p>

                                        </div>

                                    </div>

                                </div>

                                <div className="userChart">
                                    <h3>Users in the last year</h3>
                                    <UserChart data={userStats} grid dataKey="New User" />
                                </div>

                            </div>


                        </div>

                    </div>

                    {/* <Modal
                    open={openModal}
                    closeModal={() => setOpenModal(false)}
                />
 */}

                </div>

                <nav className={sidebar ? "side-menu active" : "side-menu"}>
                    <ul className="nav-menu-items" onClick={showSidebar}>
                        <li className="side-toggle">
                            <IoClose className="menuIconClose" />
                        </li>
                        <div className="name">
                            <span className='heading'>My Groups:</span>
                        </div>
                        {groups.map((group) => (
                            <li className="side-item" key={group._id} onClick={scrollToTop}>
                                <NavLink
                                    to={`/group/${group._id}`}
                                    className={({ isActive }) => "side-links" + (isActive ? " activated" : "")}
                                >
                                    <img
                                        src={group.image || "https://res.cloudinary.com/tunjooadmin/image/upload/v1683892793/Copy_of_Copy_of_m_jon6ug.png"}
                                        alt=""
                                    // className='resImg'
                                    />
                                    <p>{group.name}</p>
                                </NavLink>
                            </li>

                        ))}
                    </ul>

                </nav>

            </div>

        </div>
    )
}


export default Home


