import React, { useState, useEffect } from 'react';
import './group.scss';
import '../../Components/leftbar/leftbar.scss';
import { IoClose } from 'react-icons/io5';
import newRequest from '../../config/newRequest';
import { useLocation, Link, NavLink } from 'react-router-dom';
import LeftBar from '../../Components/leftbar/LeftBar';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import GroupMembers from '../../Components/group-tables/GroupMembers';
import GroupTransactions from '../../Components/group-tables/GroupTransactions';


const Group = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [group, setGroup] = useState({});
    const [savings, setSavings] = useState([]);

    //GET SINGLE GROUP
    useEffect(() => {
        const getGroup = async (props) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const singleGroup = await newRequest.get("/api/group/single/" + path, config);
                setGroup(singleGroup.data);
                props.setValue(singleGroup.data);
            } catch (err) { }
        };
        getGroup();
    }, [path, currentUser.accessToken, group]);

    //GET GROUP SAVINGS
    useEffect(() => {
        const getSavings = async () => {
            try {
                const res = await newRequest.get("/api/usersavings/" + group._id);
                setSavings(res.data);
            } catch { }
        };
        getSavings();

    });

    const totalSavings = () => {
        let totalIncome = 0;
        savings.forEach((income) => {
            totalIncome = totalIncome + income.amount
        })

        return totalIncome;
    }

    //FOR RESPONSIVE SIDE BAR
    const [sidegroup, setSidegroup] = useState(false);
    const showSidegroup = () => setSidegroup(!sidegroup);

    const [groups, setGroups] = useState([]);

    // //GET GROUPLIST
    useEffect(() => {
        const getGroups = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const groupList = await newRequest.get("/api/group/groups/" + currentUser._id, config);
                // const groupList = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/group/groups/" + currentUser._id, config);
                setGroups(groupList.data);
            } catch (err) {
                // console.log(err);
            }
        };
        getGroups();
    }, [currentUser]);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }



    return (

        <div className="groupContainer">

            <LeftBar />

            <div className="groupPage">

                <div className="groupWrapper">

                    <div className="groupTop">

                        <div className="left">
                            <IoIosArrowDropleftCircle className='toggle' onClick={showSidegroup} />
                            <span className='accName'>{group.name}</span>
                        </div>
                        <div className="right">

                            <Link className="link" to={`/fund-your-group/${group._id}`}>
                                <span className='editBtn' >Fund Group Account</span>
                            </Link>

                            {/* <Link  className="link" to={`/withdraw-funds/${group._id}`}>
                                <span className='withdrawBtn' >Withdraw Funds</span>
                            </Link>
                             */}
                            {currentUser._id === group.groupAdmin && (
                                <Link className="link" to={`/edit/${group._id}/${group.name}`}>
                                    <span className='editBtn' >Edit / Add to Group</span>
                                </Link>
                            )}
                        </div>

                    </div>

                    <div className="groupWidget">

                        <div className="itemDigit">
                            <h3>Group Savings</h3>
                            <div className="amountAmt">
                                <span className='savingsSign'>UGX </span>
                                <span className='savingsAmt'>{totalSavings()?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>
                        </div>

                        <div className="itemDigit">
                            <h3> Number of Members </h3>
                            <div className="count">
                                <h2>{group.users ? group.users?.length : "0"}</h2>
                            </div>
                        </div>

                    </div>


                    <div className='groupMembers'>
                        <span className='title'>Group Members:</span>
                        <GroupMembers group={group} />
                    </div>

                    <div className='groupTransactions'>
                        <span className='title'>Group Transactions:</span>
                        <GroupTransactions group={group} />
                    </div>


                </div>

            </div>

            <div className={sidegroup ? "side-menu active" : "side-menu"}>
                <ul className="nav-menu-items">
                    <li className="side-toggle" onClick={showSidegroup}>
                            <IoClose className="menuIconClose"/>
                        </li>
                        <div className="name">
                            <span className='heading'>My Groups:</span>
                        </div>
                    {groups.map((group) => (
                        <div onClick={scrollToTop}>
                            <li className="side-item" key={group._id} onClick={() => setSidegroup(false)}>
                            <NavLink
                                to={`/group/${group._id}`}
                                className={({ isActive }) => "side-links" + (isActive ? " activated" : "")}
                            >
                                <img
                                    src={group.image || "https://res.cloudinary.com/tunjooadmin/image/upload/v1683892793/Copy_of_Copy_of_m_jon6ug.png"}
                                    alt=""
                                />
                                <p>{group.name}</p>
                            </NavLink>
                        </li>

                        </div>
                    ))}
                </ul>


            </div>

        </div>

    )
}

export default Group