import React, { useState } from 'react';
import './navbar.scss';
import './modal.scss';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import { MdContactSupport } from 'react-icons/md';
import { HiHome } from 'react-icons/hi';
import { BsPersonFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { MdLogout, MdOutlineEdit, MdAccountBalanceWallet } from 'react-icons/md';
// import newRequest from '../../config/newRequest';

function NavBar() {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [click, setClick] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [openUser, setOpenUser] = useState(false);


    const handleLogout = async () => {
        try {
            await axios.post("https://regonow-backend-5bd998beedc2.herokuapp.com/api/auth/logout");
            window.localStorage.clear();
            navigate("/login");
        } catch (err) {
            console.log(err);
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <>
            <div className='navbar'>

                <div className='left'>

                    <Link to="/" style={{ textDecoration: 'none' }} onClick={closeMobileMenu}>
                        <div className="logoWrapper">
                            <img className="logoImage" src="/dotlogo.png" alt="" />
                            <span className="logoText">regonow</span>
                        </div>
                    </Link>

                </div>

                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes /> : <FaBars />}
                </div>

                <ul className={click ? "nav-menu active" : "nav-menu"}>

                    <div className='left'>

                        <li className="nav-item" onClick={scrollToTop}>
                            <NavLink
                                to={`/profile/${currentUser.username}`}
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}

                            >
                                <img
                                    src={currentUser.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                                    alt=""
                                />
                                <h4>{currentUser.firstname + " " + currentUser.lastname}</h4>
                            </NavLink>
                        </li>

                        <hr className='responsiveHr' style={{ marginTop: '-10px' }} />

                        <li className="nav-item" onClick={scrollToTop}>
                            <NavLink
                                to="/"
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}
                            >
                                <HiHome className='navLinksIcon' />
                                <span>Home</span>
                            </NavLink>
                        </li>

                        <li className="nav-item" onClick={scrollToTop}>
                            <NavLink
                                to={`/account/${currentUser.username}`}
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}
                            >
                                <BsPersonFill className='navLinksIcon' />
                                <span>Account</span>
                            </NavLink>
                        </li>

                        <li className="nav-item" onClick={scrollToTop}>
                            <NavLink
                                to="/contact-us"
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}
                            >
                                <MdContactSupport className='navLinksIcon' />
                                <span>Contact</span>
                            </NavLink>
                        </li>

                        <p className='separator'>|</p>

                    </div>

                    <hr className='responsiveHr' />

                    <div className='right'>
                        <div className='currentUser'>
                            <li className="nav-item">
                                {currentUser &&
                                    <div className="currentUser" onClick={() => setOpenUser(!openUser)}>
                                        <img
                                            src={currentUser.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                                            alt=""
                                        />
                                        {/* <span className='userName'>{currentUser.username}</span> */}
                                        <span className='userName'>{currentUser.firstname + " " + currentUser.lastname}</span>
                                    </div>
                                }
                            </li>
                        </div>


                        {openUser && (
                            <div className="options" onClick={() => setOpenUser(!openUser)}>
                                <Link className="link" to={`/member/edit/` + currentUser._id}>
                                    <div className="item" >
                                        <MdOutlineEdit className='icon' />
                                        <span className='optionsText'>Edit Profile</span>
                                    </div>
                                </Link>

                                <Link className="link" onClick={handleLogout}>
                                    <div className="itemLogout">
                                        <MdLogout className='icon' />
                                        <span className='LogoutText'>Logout</span>
                                    </div>
                                </Link>
                            </div>
                        )}

                        <li className="edit-btn">
                            <NavLink
                                to={`/member/edit/` + currentUser._id}
                                style={{ color: 'inherit', textDecoration: 'none' }}
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}
                            >
                                Edit Profile
                            </NavLink>
                        </li>

                        <li className="nav-btn1" onClick={handleLogout}>
                            <NavLink
                                to="/"
                                style={{ color: 'inherit', textDecoration: 'none' }}
                                className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")}
                                onClick={closeMobileMenu}
                            >
                                LOGOUT
                            </NavLink>
                        </li>

                    </div>


                </ul>

            </div>

        </>
    )
}



export default NavBar;











