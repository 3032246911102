import React, { useState, useEffect } from 'react';
import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import moment from 'moment';
import newRequest from '../../config/newRequest';

const TableComponent = ({group}) => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [transactions, setTransactions] = useState([]);

        //GET GROUP TRANSACTIONS
        useEffect(() => {
            const getTransactions = async () => {
                try {
                    const res = await newRequest.get("/api/usersavings/user/" + currentUser._id);
                    setTransactions(
                        res.data.sort((p1, p2) => {
                            return new Date(p2.createdAt) - new Date(p1.createdAt);
                        })
                    );
                } catch { }
            };
            getTransactions();

        });


    //PAGINATION
    const [pageSize, setPageSize] = useState(8);
    const [page, setPage] = useState(1);
    const handlePage = (page) => setPage(page);
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    };
    const totalPages = Math.ceil(transactions.length / pageSize);
    const pageContent = transactions.slice((page - 1) * pageSize, page * pageSize);


    return (
        <TableContainer component={Paper} className='table'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='tableCellTitle'>No.</TableCell>
                        <TableCell className='tableCellTitle'>Date</TableCell>
                        <TableCell className='tableCellTitle'>Group</TableCell>
                        <TableCell className='tableCellTitle'>Description</TableCell>
                        <TableCell className='tableCellTitle'>Type</TableCell>
                        <TableCell className='tableCellTitle'>Amount</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody name="page-size" id="page-size" onChange={handlePageSizeChange}>
                    {pageContent.map((transaction, index) => (
                        <TableRow key={transaction._id}>
                            
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell className='tableCell'>
                                <span  className='tableCellText'>{moment(transaction.createdAt).format('DD / MM / YYYY')}</span>
                                </TableCell>
                            <TableCell className='tableCell'>
                            <span  className='tableCellText'>{transaction.groupName}</span>
                            </TableCell>
                            <TableCell className='tableCell'>
                            <span  className='tableCellText'>{transaction.desc}</span>
                            </TableCell>
                            <TableCell className='tableCell'>
                                <span className={`type ${transaction.type}`}>{transaction.type}</span>
                            </TableCell>

                            <TableCell className='tableCell'>
                                <span className='tableCellText2'>{transaction.amount?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>

            </Table>

            <div className="pagination">
                <Pagination
                    className='pagination'
                    count={totalPages}
                    onChange={(event, value) => handlePage(value)}
                    page={page}
                    size="small"
                ></Pagination>
            </div>

        </TableContainer>
    )
}

export default TableComponent




