import React, { useState, useEffect } from 'react';
import './leftbar.scss';
import { NavLink } from "react-router-dom";
// import newRequest from '../../config/newRequest';
import axios from 'axios';
import newRequest from '../../config/newRequest';


function LeftBar() {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [groups, setGroups] = useState([]);

    // //GET GROUPLIST
    useEffect(() => {
        const getGroups = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const groupList = await newRequest.get("/api/group/groups/" + currentUser._id, config);
                // const groupList = await axios.get("https://regonow-backend-5bd998beedc2.herokuapp.com/api/group/groups/" + currentUser._id, config);
                setGroups(groupList.data);
            } catch (err) {
                // console.log(err);
            }
        };
        getGroups();
    }, [currentUser]);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='leftbar'>

            <div className="wrapper">
                <div className='leftbarContainer'>

                    <ul>
                        <div className="groupName">
                            <span className='heading'>My Groups:</span>
                        </div>

                        {groups.map((group) => (
                            <li className="side-item" key={group._id} onClick={scrollToTop}>
                                <NavLink
                                    to={`/group/${group._id}`}
                                    className={({ isActive }) => "side-links" + (isActive ? " activated" : "")}
                                >
                                    <img
                                        src={group.image || "https://res.cloudinary.com/tunjooadmin/image/upload/v1683892793/Copy_of_Copy_of_m_jon6ug.png"}
                                        alt=""
                                    />
                                    <p>{group.name}</p>
                                </NavLink>
                            </li>

                        ))}
                    </ul>



                </div>
            </div>
        </div>
    )
}

export default LeftBar