import React from "react";
import './reset.scss';
import { Link } from "react-router-dom";

const Modal = (props) => {

    return (
        <div className="Overlay">
           
            <div className="Modal">
                <div className="header">
                    <h2>{props.title}</h2>
                </div>
                <div className="content">
                    <p>{props.message}</p>
                </div>
                
                    <Link to="/login" className="link">
                        <div className="actions">Login</div>
                    </Link>
                
            </div>
        </div>
    );
}

export default Modal