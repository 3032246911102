import React, { useRef, useState } from 'react';
import './deposit.scss';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';


const FundWallet = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const fullname = currentUser.firstname + " " + currentUser.lastname;

    const [val, setVal] = useState('');

    const inputRef = useRef();

    const submitHandler = (e) => {
        e.preventDefault();
        setVal(inputRef.current.value);
    }

    //FLUTTERWAVE
    const config = {
        public_key:'FLWPUBK-497d0bed055b6ee9ff10535318eff276-X',
        tx_ref: Date.now(),
        amount: val,
        currency: "UGX",
        country: "UG",
        payment_options: "card, mobilemoneyuganda, ussd",

        // specified redirect URL
        redirect_url: "https://members.rego-now.com/response",

        // use customer details if user is not logged in, else add user_id to the request
        customer: {
            email: currentUser.email,
            phone_number: currentUser.phone,
            name: fullname,
        },
        callback: function (data) {
            console.log(data);
        },
        onclose: function () {
            // close modal
        },
        customizations: {
            title: "Regonow Limited",
            description: "Group Savings",
            logo: "https://res.cloudinary.com/tunjooadmin/image/upload/v1689380801/regonow/dotlogo_j1dgpe.png",
        },
    };

    const handleFlutterPayment = useFlutterwave(config);


    return (

        <div className='mainWalletWrapper'>

        <div className='mainWallet'>

            {/* <div className="logo">
                <img className="logoImage" src={process.env.PUBLIC_URL + '/dotlogo.png'} alt="logo" />
                <span className="logoText">regonow</span>
            </div> */}

            <div className="wrapper">
                <div className="top">

                    <div className="title">
                        <h2>Fund your Wallet </h2>
                    </div>

                    <div className="amountWrapper">
                        <form onSubmit={submitHandler}>
                            <input placeholder='Enter Amount e.g 20000' ref={inputRef} />
                            <button style={{backgroundColor:"transparent"}} type="submit" className='submitBtn'>Submit</button>
                        </form>

                        <div className="amount">
                            <p>Savings Amount: </p>
                            <b>{val}</b>
                            {/* <b>{groupwallet.inputRef}</b> */}
                        </div>

                    </div>

                </div>

                <div className="bottom">

                    <button className='proceedBtn'
                        onClick={() => {
                            handleFlutterPayment({
                                callback: (response) => {
                                    console.log(response);
                                    closePaymentModal() // this will close the modal programmatically
                                },
                                onClose: () => { },
                            });
                        }}
                    >
                        PAY NOW
                    </button>

                </div>
            </div>
        </div>

        </div>

    )
}

export default FundWallet