import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './login.scss';

function MemberSuccessModal() {

    return (

        <div className="success">

            <div className="successModal">

                <FaCheckCircle className="modalIcon" />
                <h1>Success!</h1>
                <p>Your account has been created.</p>

                <Link to="/login">
                    <button className="modalBtn">Get Started</button>
                </Link>

            </div>

        </div>

    )

}

export default MemberSuccessModal



