import React, { useState, useEffect } from 'react';
import './account.scss';
import newRequest from '../../config/newRequest';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import AccountTable from '../../Components/account-table/AccountTable';
import AccGroupTable from '../../Components/account-table/AccGroupTable';
import ProfileBar from './ProfileBar';
import DepositGroup from '../../forms/modals/DepositGroup';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { MdOutlineHomeWork, MdOutlineSchool } from 'react-icons/md';
import { VscMail } from 'react-icons/vsc';
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlinePhone, HiOutlineIdentification } from 'react-icons/hi'


const Account = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [wallet, setWallet] = useState({});

    //FOR RESPONSIVE SIDE PROFILE
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    /// Fund Group Account
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const FundGroupModal = ({ open, closeModal }) => {
        if (!open) return null;
        return (
            <div onClick={closeModal} className='Overlay'>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="Modal"
                >
                    <DepositGroup />

                    <IoClose style={{
                        width: 35,
                        height: 35,
                        color: '#e4405f',
                        cursor: 'pointer',
                    }}
                        onClick={closeModal}
                    />
                </div>
            </div>
        );
    };


    //Get User Wallet
    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${currentUser.accessToken}`,
            },
        };
        const fetchWallet = async () => {
            const res = await newRequest.get("/api/wallet/find/" + currentUser._id, config)
            setWallet(res.data)
        }
        fetchWallet();
    });


    return (
        <div className="accContainer">

            <ProfileBar />

            <div className='accountPage'>

                <div className='pageWrapper'>

                    <div className="widgetsTop">
                        <div className="left">
                            <IoIosArrowDropleftCircle className='toggle' onClick={showSidebar} />
                            {/* <RiAccountBoxFill className='accIcon' /> */}
                            <span className='accName'>{currentUser.firstname + " " + currentUser.lastname}</span>
                        </div>
                        <div className="right">
                            <Link to="/fund-your-wallet">
                                <button className='saveBtn'>DEPOSIT SAVINGS</button>
                            </Link>
                        </div>

                    </div>

                    <div className="balanceWrapper">
                        <div className="balances">
                            <span className='accTitle1'>Personal Savings Account</span>
                            <hr />
                            <div className="balItem">
                                <span className='balItemText'>Account Number</span>
                                <span className='balItemText'>Balance</span>
                            </div>
                            <div className="balItem">
                                {/* <span className='balItemText'>RN00{currentUser._id?.toLocaleString().substring(0, 8)}</span> */}
                                <span className='balItemText'>RN00{currentUser._id.replace(/\D/g, "")}</span>
                                <div className='moneyWrapper'>
                                    <span className='balItemSign'>UGX </span>
                                    <span className='balItemAmount'>{wallet ? wallet.balance?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "0.00"}</span>
                                </div>
                            </div>
                            <hr />

                        </div>

                    </div>

                    <div className='walletTransactions'>
                        <span className='title'>Wallet Transactions:</span>
                        <AccountTable />
                    </div>

                    <div className='groupTransactions'>
                        <span className='title'>Group Transactions:</span>
                        <AccGroupTable />
                    </div>

                    {/* <div className='walletTransactions'>
                        <span className='title'>Wallet Transactions:</span>
                        <AccountTable />
                    </div> */}


                </div>
            </div>


            <div className={sidebar ? "side-profile active" : "side-profile"}>
                <div className="side-toggle" onClick={showSidebar}>
                    <IoClose className="menuIconClose" />
                </div>
                <div className='resWrapper'>

                    <div className="userImage">
                        <img
                            src={currentUser.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                            alt='currentUser'
                        />
                        <span>{currentUser.firstname + " " + currentUser.lastname}</span>
                        {/* <p>RN00{currentUser._id?.toLocaleString().substring(0, 8)}</p> */}
                        <p>RN00{currentUser._id.replace(/\D/g, "")}</p>

                    </div>

                    <div className="items">
                        <div className="item">
                            <HiOutlineIdentification className='icon' />
                            <span>{currentUser.nin}</span>
                        </div>
                        <div className="item">
                            <VscMail className='icon' />
                            <span>{currentUser.email}</span>
                        </div>
                        <div className="item">
                            <HiOutlinePhone className='icon' />
                            <span>{currentUser.phone}</span>
                        </div>
                        <div className="item">
                            <MdOutlineSchool className='icon' />
                            <span>{currentUser.jobTitle}</span>
                        </div>
                        <div className="item">
                            <MdOutlineHomeWork className='icon' />
                            <span>{currentUser.employer}</span>
                        </div>
                        <div className="item">
                            <IoLocationOutline className='icon' />
                            <span>{currentUser.address}</span>
                        </div>
                    </div>

                </div>
            </div>

            <FundGroupModal
                open={openGroupModal}
                closeModal={() => setOpenGroupModal(false)}
            />


        </div>
    )
}

export default Account