import React, { useState } from 'react';
import './response.scss';
import newRequest from '../../config/newRequest';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdGppGood } from 'react-icons/md';
import moment from 'moment';
// import axios from 'axios';

const Response = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [response, setResponse] = useState();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('transaction_id')

    //FETCH RESPONSE
    useEffect(() => {
            const fetchResponse = async () => {
                const res = await newRequest.get(`/api/wallet/response?transaction_id=${id}`);
                setResponse(res.data);
            };
            fetchResponse();
    });


    return (
        <div className='response'>
            {response && (
                <div className="responseData">
                    <div className="user">
                        <img src={currentUser.profile} alt="" />
                        <h3>{response.customer.name}</h3>
                        <span>{response.customer.email}</span>
                        <span>{response.customer.phone_number}</span>
                    </div>

                    <div className="responseStats">
                        <MdGppGood className="icon" />
                        <h2>{response.amount} ugx</h2>
                        <h5>{response.response}!!</h5>
                    </div>

                    <hr />

                    <div className="bottomResponse">
                        <div className="left">
                            <p>Transaction Ref</p>
                            <h4>{response.tx_ref}</h4>
                            <p>{moment(response.created_at).format("Do MMM YYYY, h:mm a")}</p>
                        </div>

                        <div className="right">
                            <p>Payment Type</p>
                            <h4>{response.payment_type}</h4>
                            <p>{response.status}</p>
                        </div>

                    </div>

                    <Link className="link" to={`/account/${currentUser.username}`}>
                        <span className='accountBtn'>Go to Account</span>
                    </Link>

                </div>
            )}
        </div>
    )
}

export default Response