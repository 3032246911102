import React from 'react';
import './user.scss';
import { Text } from "@chakra-ui/layout";


const UserItem = ({ handleFunction, user }) => {

    return (
        <div className="userWrapper" onClick={handleFunction}>
            <img src={user.profile} alt=""/>
            <div className="userText">
                <Text style={{fontSize: 12, }}>{user.firstname + " " + user.lastname}</Text>
                <Text style={{fontSize: 12, }}>
                    phone: { "  " + user.phone}
                </Text>
            </div>
        </div>
    );
};

export default UserItem;