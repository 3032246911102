import React, { useState, useEffect } from 'react';
import './single.scss';
import { MdEmail, MdAccountBox } from 'react-icons/md';
import { HiClock } from 'react-icons/hi';
import { FaAddressCard, FaPhoneSquareAlt } from 'react-icons/fa';
import { FaBriefcase, FaHome } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import newRequest from '../../config/newRequest';
import Withdraw from "../../forms/modals/Withdraw";
// import axios from 'axios';
import moment from 'moment';


const SingleMember = () => {

    const { username } = useParams();
    const [member, setMember] = useState({});
    const [wallets, setWallets] = useState([]);
    const [group, setGroup] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/")[1];

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // GET USER
    useEffect(() => {
        const fetchMember = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            const res = await newRequest.get(`/api/user/users?username=${username}`, config);
            setMember(res.data);
        };
        fetchMember();
    }, [username, currentUser.accessToken]);

    //GET WALLET LIST
    useEffect(() => {

        const fetchWallet = async () => {
            const res = await newRequest.get("/api/wallet/find/group/" + path)
            setWallets(res.data)
        }
        fetchWallet();
    });

    //GET GROUP ADMIN
    useEffect(() => {
        const getGroup = async (props) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const singleGroup = await newRequest.get("/api/group/single/" + path, config);
                setGroup(singleGroup.data);
            } catch (err) { }
        };
        getGroup();
    }, [path, currentUser.accessToken, group]);

    //REMOVE MEMBER FROM GROUP
    const handleRemove = async (user1) => {

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            await newRequest.put("/api/group/del/" + member._id,
                {
                    groupId: group._id,
                    userId: member._id,
                },
                config
            );
            alert("Member has been removed!!");
            navigate(`/group/${group._id}`);
        } catch (error) {
            setError(error);
        }
    };


    return (
        <div className='singleMember'>
            <div className="widgetsTop">

                <div className="item">
                    <span>Total Savings in {group.name}</span>

                    {wallets.map((wallet) =>
                        member._id === wallet.userId && (
                            <div className='text' key={wallet._id}>
                                <p>UGX: </p>
                                <h2>{wallet.balance?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h2>
                            </div>
                        )

                    )}

                </div>


            </div>

            <div className="profilePage">

                <div className='profilePageWrapper'>

                    <div className="imageWrapper">
                        <div className="profileCover">
                            <img src={member.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"} alt="" />
                        </div>
                        {group.groupAdmin === currentUser._id &&
                            <span className='deleteMember' onClick={handleRemove}>Remove Member</span>
                        }
                    </div>

                    <div className="profileInfo">

                        <h2 className="profileName">{member.firstname + " " + member.lastname}</h2>

                        <div className="profileInfoItem">
                            <MdAccountBox className='icon' />
                            <div className="infoText">
                                <span className='detail'>Acoount No:</span>
                                <p>RN00{currentUser._id.replace(/\D/g, "")}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <FaAddressCard className='icon' />
                            <div className="infoText">
                                <span className='detail'>NIN:</span>
                                <p>{member.nin}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <MdEmail className='icon' />
                            <div className="infoText">
                                <span className='detail'>Email:</span>
                                <p>{member.email}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <FaPhoneSquareAlt className='icon' />
                            <div className="infoText">
                                <span className='detail'>Phone No:</span>
                                <p>{member.phone}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <FaHome className='icon' />
                            <div className="infoText">
                                <span className='detail'>Address:</span>
                                <p>{member.address}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <FaBriefcase className='icon' />
                            <div className="infoText">
                                <p>{member.jobTitle}</p>
                                <span className='detail'>at</span>
                                <p>{member.employer}</p>
                            </div>
                        </div>

                        <div className="profileInfoItem">
                            <HiClock className='icon' />
                            <div className="infoText">
                                <span className='detail'>Joined:</span>
                                <p>{moment(member.createdAt).format("Do MMMM YYYY")}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <span style={{ color: 'red', marginTop: 20, fontSize: 13 }}>{error && error}</span>


            {group.groupAdmin === currentUser._id &&

                <span
                    style={{ color: '#525355', fontSize: 15, letterSpacing: 0.5 }}>
                    * Withdraws will be active 90days after group registration *
                </span>

                // <Withdraw group={group} member={member} wallets={wallets} />
            }

        </div>
    )
}

export default SingleMember

















