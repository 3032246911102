import React, { useState, useRef } from 'react';
import './contact.scss';
import emailjs from '@emailjs/browser';
import { MdLocalPhone, MdEmail } from 'react-icons/md';

const Contact = () => {

    const form = useRef();
    const [message, setMessage] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_6r7zgoj',
            'template_enun75f',
            form.current,
            '42RF3vmFQabBfhntU'
        )
            .then((result) => {
                console.log(result.text);
                setMessage('Thanks, We shall reply ASAP!');
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };



    return (
        <div className='contact'>

            <div className="top">

                <div className="itemWrapper">
                    <div className="iconWrapper">
                        <MdLocalPhone className='icon' />
                    </div>
                    <div className="contactText">
                        <h3>+256 775 326 157</h3>
                        <p>Mon-Fri, 8am until 5pm</p>
                    </div>
                </div>

                <div className="itemWrapper">
                    <div className="iconWrapper">
                        <MdEmail className='icon' />
                    </div>
                    <div className="contactText">
                        <span>support@rego-now.com</span>
                        <p>We reply within 24 hours</p>
                    </div>
                </div>

            </div>

            <div className="bottom">
                <div className="top">
                    <h1>Write to us...</h1>
                </div>

                <form ref={form} onSubmit={sendEmail}>

                    <div className="formSec1">

                        <input className="inputWrapper" type="text" name="user_name" placeholder='Full Name' />
                        <input className="inputWrapper" type="email" name="user_email" placeholder='Email' />

                    </div>

                    <textarea name="message" rows="10" placeholder='Message' />
                    <input type="submit" value="SEND" className="contactFormBtn" />
                    {message && <span>Thanks, We shall reply ASAP!</span>}

                </form>


            </div>

        </div>
    )
}

export default Contact