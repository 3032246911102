import React, { useState, useEffect } from 'react';
import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import newRequest from '../../config/newRequest';
import { Link } from 'react-router-dom';



const GroupMembers = ({ group }) => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [members, setMembers] = useState([]);
    const [wallets, setWallets] = useState([]);

    //GET GROUP MEMBERS LIST
    useEffect(() => {
        const getMembers = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const memberList = await newRequest.get("/api/group/members/" + group._id, config);
                setMembers(memberList.data);
            } catch (err) { }
        };
        getMembers();
    }, [group._id, currentUser.accessToken]);


    //GET WALLET LIST
    useEffect(() => {

        const fetchWallet = async () => {
            const res = await newRequest.get("/api/wallet/find/group/" + group._id)
            setWallets(res.data)
        }
        fetchWallet();
    });


    //PAGINATION
    const [pageSize, setPageSize] = useState(8);
    const [page, setPage] = useState(1);
    const handlePage = (page) => setPage(page);
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    };
    const totalPages = Math.ceil(members.length / pageSize);
    const pageContent = members.slice((page - 1) * pageSize, page * pageSize);


    return (
        <TableContainer component={Paper} className='table'>
            <Table sx={{ minWidth: 680 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='tableCellTitle'>No.</TableCell>
                        <TableCell className='tableCellTitle'>Member</TableCell>
                        <TableCell className='tableCellTitle'>Account No.</TableCell>
                        <TableCell className='tableCellTitle'>Job Title</TableCell>
                        <TableCell className='tableCellTitle'>Savings(Ugx)</TableCell>
                        <TableCell className='tableCellTitle'>View</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody name="page-size" id="page-size" onChange={handlePageSizeChange}>
                    {pageContent.map((member, index) => (
                        <TableRow key={member._id}>

                            <TableCell>{index + 1}.</TableCell>

                            <TableCell className='tableCell2'>{
                                <div className="member">
                                    <img
                                        className='memberImage'
                                        src={member.profile ? member.profile : "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                                        alt=""
                                    />
                                    <p className='memberName'>{member.firstname + " " + member.lastname}</p>
                                </div>
                            }</TableCell>
                            <TableCell>
                                <p className='memberID'>RN00{member._id?.toLocaleString().substring(0, 6)}</p>
                            </TableCell>
                            <TableCell className='tableCell2'>
                                <p className='memberName2'>{member.jobTitle}</p>
                            </TableCell>

                            <TableCell className='tableCell2'>
                                {wallets.map((wallet) =>
                                    member._id === wallet.userId && (
                                        <span className="walletBal" key={wallet._id}>{wallet.balance?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                    )
                                )}
                            </TableCell>

                            <TableCell className='tableCell2'>
                                <Link className="memberBtn" to={`/${group._id}/${member.username}`} style={{ textDecoration: "none", color: "inherit" }}>
                                    <span>View</span>
                                </Link>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>

            </Table>

            <div className="pagination">
                <Pagination
                    className='pagination'
                    count={totalPages}
                    onChange={(event, value) => handlePage(value)}
                    page={page}
                    size="small"
                ></Pagination>
            </div>

        </TableContainer>
    )
}

export default GroupMembers








