import React, { useState, useEffect } from 'react';
import './deposit.scss';
import newRequest from '../../config/newRequest';
import { useLocation, useNavigate } from 'react-router-dom';


const DepositGroup = () => {

    const [err, setErr] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [group, setGroup] = useState({});

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    //GET SINGLE GROUP
    useEffect(() => {
        const getGroup = async (props) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            try {
                const singleGroup = await newRequest.get("/api/group/single/" + path, config);
                setGroup(singleGroup.data);
            } catch (err) {}
        };
        getGroup();
    }, [path, currentUser.accessToken, group]);


    const fullname = currentUser.firstname + " " + currentUser.lastname;
    

    const [groupSaving, setGroupSaving] = useState({
        fullname: fullname,
        amount: "",
        desc: "",
    });

    console.log(groupSaving)

    const handleChange = (e) => {
        setGroupSaving((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    function refreshPage(){ 
        window.location.reload(); 
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };

            const newTransaction = {
                ...groupSaving,
                groupName: group.name,
                groupId: group._id,
            };
            await newRequest.post("/api/usersavings", newTransaction, config);
            navigate(`/group/${group._id}`);
        } catch (err) {
            setErr(err.response.data);
        }
    };


    return (
        <div className='depositPage'>

            <div className='formWrapper'>

                <span className='headingText'>Send Funds to Your Group Account</span>
                <span className='groupName'>Group: {group.name}</span>

                <form onSubmit={handleSubmit}>

                    <div className="formItem">
                        <div className="item">
                            <div className="itemtop">
                                <label>Full Name</label>
                                <p>Do not Edit*</p>
                            </div>
                            <input
                                placeholder='e.g Rent'
                                type="text"
                                name="fullname"
                                value={groupSaving.fullname}
                                onChange={handleChange}
                            />
                        </div>

                    </div>

                    <span className='subHeading'>Savings Details:</span>

                    <div className="item">
                        <label>Description</label>
                        <input
                            placeholder='e.g Rent for October'
                            type="text"
                            name="desc"
                            value={groupSaving.desc}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="item">
                        <label>Amount</label>
                        <input
                            placeholder='e.g 11000'
                            type="number"
                            name="amount"
                            value={groupSaving.amount}
                            onChange={handleChange}
                        />
                    </div>

                    <button type="submit" className='addBtn'>
                        <p>Submit</p>
                    </button>

                    <div className="error">
                        <span style={{ color: '#e22d64', fontSize: 14 }}>{err && err}</span>
                        {err && <button type="button" onClick={refreshPage}> <span>TRY AGAIN</span> </button>}
                    </div>

                </form>

            </div>

        </div>
    )
}

export default DepositGroup












