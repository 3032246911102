import React from 'react';
import './userchart.scss';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';



const UserChart = ({ data, dataKey }) => {

   return (

      <div className='userChart'>

         <ResponsiveContainer width="100%" aspect={3.8/1}>
            <AreaChart data={data} allowDuplicatedCategories={false}>
               <XAxis dataKey="name" stroke="gray" allowDuplicatedCategory={false} />
               <CartesianGrid strokeDasharray="3 3" className='chartGrid' />
               <Tooltip />
               <Area type="monotone" dataKey={dataKey} stroke="#009d4b" fillOpacity={1} fill="url(#total)" />
            </AreaChart>
         </ResponsiveContainer>


      </div>
   )
}

export default UserChart

