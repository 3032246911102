import React from 'react';
import './account.scss';
import { MdOutlineHomeWork, MdOutlineSchool } from 'react-icons/md';
import { VscMail } from 'react-icons/vsc';
import { IoLocationOutline  } from "react-icons/io5";
import { HiOutlinePhone, HiOutlineIdentification } from 'react-icons/hi'


const ProfileBar = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    return (
        <div className='profilebar'>
            <div className='profileContainer'>

                <div className="userImage">
                    <img
                        src={currentUser.profile || "https://res.cloudinary.com/tunjooadmin/image/upload/v1679634861/upload/avatar1_klacib.png"}
                        alt='currentUser'
                    />
                    <span>{currentUser.firstname + " " + currentUser.lastname}</span>
                    <p>RN00{currentUser._id.replace(/\D/g, "")}</p>
                </div>

                <div className="item">
                    <HiOutlineIdentification className='icon'/>
                    <span>{currentUser.nin}</span>
                </div>
                <div className="item">
                    <VscMail className='icon'/>
                    <span>{currentUser.email}</span>
                </div>
                <div className="item">
                    <HiOutlinePhone className='icon'/>
                    <span>{currentUser.phone}</span>
                </div>
                <div className="item">
                    <MdOutlineSchool className='icon'/>
                    <span>{currentUser.jobTitle}</span>
                </div>
                <div className="item">
                    <MdOutlineHomeWork className='icon'/>
                    <span>{currentUser.employer}</span>
                </div>
                <div className="item">
                    <IoLocationOutline className='icon'/>
                    <span>{currentUser.address}</span>
                </div>

            </div>

        </div>
    )
}

export default ProfileBar