import React from 'react';
import './user.scss';
import { IoClose } from 'react-icons/io5';

const SelectedUser = ({user, handleFunction}) => {
    return (
        <div className='selectedUser' >
            {user.firstname + " " + user.lastname}
            <IoClose className='icon' onClick={handleFunction}/>
        </div>
    )
}

export default SelectedUser