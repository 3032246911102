import React from 'react';
import { useState } from 'react';
import './newGroup.scss';
import { useNavigate } from 'react-router-dom';
import newRequest from '../../config/newRequest';
import { useToast } from "@chakra-ui/react";
import UserItem from '../users/UserItem';
import SelectedUser from '../users/SelectedUser';


const NewGroup = ({ closeModal }) => {

    const [groupName, setGroupName] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const toast = useToast();
    const navigate = useNavigate();

    
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const handleGroup = (userToAdd) => {
        if (selectedUsers.includes(userToAdd)) {
            toast({
                title: "User already added",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        setSelectedUsers([...selectedUsers, userToAdd]);
    };

    const handleSearch = async (query) => {
        setSearch(query);
        if (!query) {
            return;
        }

        try {
            setLoading(true);
            const { data } = await newRequest.get(`/api/user?search=${search}`);
            console.log(data);
            setLoading(false);
            setSearchResult(data);
        } catch (error) {
            toast({
                title: "Error Occured!",
                description: "Failed to Load the Search Results",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    };

    const handleDelete = (delUser) => {
        setSelectedUsers(selectedUsers.filter((sel) => sel._id !== delUser._id));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!groupName || !selectedUsers) {
            toast({
              title: "Please fill all the feilds",
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            return;
        }
      
        try {
            const config = {
                headers: {
                  Authorization: `Bearer ${currentUser.accessToken}`,
                },
            };
            
            await newRequest.post("/api/group",
                {
                    name: groupName,
                    users: JSON.stringify(selectedUsers.map((u) => u._id)),
                },
                config
            );
            navigate("/")
        } catch (error) {
            setError(error);
        }
    };



    return (

        <div className="newGroup">
            <span>New Group</span>

            <form className="newGroupWrapper" onSubmit={handleSubmit}>

                <div className="wrapperItem">
                    <div className="item">
                        <label>Group Name</label>
                        <input
                            type="text"
                            value={groupName}
                            placeholder='Group Name'
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    </div>
                    <div className="item">
                        <label>Members</label>
                        <input
                            type="text"
                            placeholder='e.g: Nancy,  John,  Tunjoo'
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>

                    <div className="select">

                        {/* selected users */}
                        <div className='selectedUsers'>
                            {selectedUsers.map((u) => (
                            <SelectedUser
                                key={u._id}
                                user={u}
                                handleFunction={() => handleDelete(u)}
                            />
                            ))}
                        </div>

                        {/* render searched users */}
                        {loading ? (
                            <div style={{ color: 'gray' }}>Loading...</div>
                        ) : (
                            searchResult
                                ?.slice(0, 4)
                              .map((user) => (
                                <UserItem
                                  key={user._id}
                                  user={user}
                                  handleFunction={() => handleGroup(user)}
                                />
                              ))
                        )}
                    </div>

                    <button type="submit" className="createBtn">Create Group</button>
                
                   {error && error}

                </div>

            </form>


        </div>
    )
}

export default NewGroup